import React from "react"

import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import ContactForm from "../components/ContactForm"
import SocialMediaCircles from "../components/SocialMediaCircles"

import useTranslation from "../translations/translate"

import FlagUS from "../components/assets/media/flags/1x1/us.svg"
import FlagCA from "../components/assets/media/flags/1x1/ca.svg"
import FlagMX from "../components/assets/media/flags/1x1/mx.svg"

import FlagAU from "../components/assets/media/flags/1x1/au.svg"
import FlagNZ from "../components/assets/media/flags/1x1/nz.svg"

import FlagES from "../components/assets/media/flags/1x1/es.svg"
import FlagPT from "../components/assets/media/flags/1x1/pt.svg"

import FlagAT from "../components/assets/media/flags/1x1/at.svg"
import FlagSI from "../components/assets/media/flags/1x1/si.svg"

import FlagIT from "../components/assets/media/flags/1x1/it.svg"

const Contact = ({ pageContext: { pageName } }) => {
  const { t } = useTranslation()

  return (
    <>
      <SEO
        title={t("page.contact.title")}
        description={t("page.contact.description")}
        pageName={pageName}
      />
      <Navbar />

      <section className="container content d-flex flex-column pt-0">
        <div className="row my-auto flex-column-reverse flex-md-row">
          <div className="col-12 col-md-6 my-auto text-center">
            <h1>{t("page.contact.title")}</h1>

            <p>
              <a href="mailto:info@evobend.com" className="text-body">
                info@evobend.com
              </a>
              <br />
              <a href="tel:004986546824440" className="text-body">
                +49 8654 6824 440
              </a>
            </p>

            <p className="mb-6">
              Am Schmidinger Weiher 3 <br />
              83404 Ainring <br />
              {t("common.country.germany")}
            </p>

            <SocialMediaCircles />
          </div>

          <div className="col-12 col-md-6 mb-5 my-md-auto">
            <ContactForm />
          </div>
        </div>

        <div className="card shadow mt-12">
          <div className="card-body p-10">
            <h2 className="mb-10 text-uppercase">
              {t("page.contact.regionalPartners")}
            </h2>

            <div className="row mb-10">
              <div className="col-12 col-md-6 d-flex flex-column">
                <div>
                  <img
                    src={FlagIT}
                    alt="IT"
                    className="rounded-circle mb-3"
                    width="60px"
                  />
                </div>

                <p className="h2">Alpemac</p>

                <p>
                  <a href="mailto:info@alpemac.it" className="text-body">
                    info@alpemac.it
                  </a>
                  <br />
                  <a href="tel:+39 030 2061781" className="text-body">
                    +39 030 2061781
                  </a>
                </p>

                <p className="mb-6">
                  Via Artigianato, 2
                  <br />
                  25011 Calcinato BS
                  <br />
                  Italia
                </p>
              </div>

              <div className="col-12 col-md-6 d-flex flex-column">
                <div>
                  <img
                    src={FlagAT}
                    alt="AT"
                    className="rounded-circle me-3 mb-3"
                    width="60px"
                  />

                  <img
                    src={FlagSI}
                    alt="SI"
                    className="rounded-circle mb-3"
                    width="60px"
                  />
                </div>

                <p className="h2">Alpemac Maschinen</p>

                <p>
                  <a href="mailto:office@alpemac.at" className="text-body">
                    office@alpemac.at
                  </a>
                  <br />
                  <a href="tel:+43 (0) 2774 30444" className="text-body">
                    +43 (0) 2774 30444
                  </a>
                </p>
                <p className="mb-6">
                  Gewerbepark Süd 9b
                  <br />
                  3052 Neustift-Innermanzing
                  <br />
                  Österreich
                </p>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-12 col-md-6 d-flex flex-column">
                <div>
                  <img
                    src={FlagUS}
                    alt="US"
                    className="rounded-circle me-3 mb-3"
                    width="60px"
                  />
                  <img
                    src={FlagCA}
                    alt="CA"
                    className="rounded-circle me-3 mb-3"
                    width="60px"
                  />
                  <img
                    src={FlagMX}
                    alt="MX"
                    className="rounded-circle mb-3"
                    width="60px"
                  />
                </div>

                <p className="h2">Roper Whitney</p>

                <p>
                  <a href="mailto:info@roperwhitney.com" className="text-body">
                    info@roperwhitney.com
                  </a>
                  <br />
                  <a href="tel:815-962-3011" className="text-body">
                    815-962-3011
                  </a>
                </p>

                <p className="mb-6">
                  2833 Huffman Blvd. <br />
                  Rockford, Illinois 61103 <br />
                  USA
                </p>
              </div>

              <div className="col-12 col-md-6 d-flex flex-column">
                <div>
                  <img
                    src={FlagAU}
                    alt="AU"
                    className="rounded-circle me-3 mb-3"
                    width="60px"
                  />

                  <img
                    src={FlagNZ}
                    alt="NZ"
                    className="rounded-circle mb-3"
                    width="60px"
                  />
                </div>

                <p className="h2">Sheetmetal Machinery</p>

                <p>
                  <a
                    href="mailto:sales@sheetmetalmachinery.com.au"
                    className="text-body"
                  >
                    sales@sheetmetalmachinery.com.au
                  </a>
                  <br />
                  <a href="tel:+613 9758 9377" className="text-body">
                    +613 9758 9377
                  </a>
                </p>
                <p className="mb-6">
                  Unit 45/756 Burwood Highway
                  <br />
                  Ferntree Gully VIC 3156
                  <br />
                  Australia
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 d-flex flex-column">
                <div>
                  <img
                    src={FlagES}
                    alt="PT"
                    className="rounded-circle  me-3 mb-3"
                    width="60px"
                  />
                  <img
                    src={FlagPT}
                    alt="PT"
                    className="rounded-circle mb-3"
                    width="60px"
                  />
                </div>

                <p className="h2">Maqfort</p>

                <p>
                  <a href="mailto:sales@maqfort.com" className="text-body">
                    sales@maqfort.com
                  </a>
                  <br />
                  <a href="tel:+351 252 310 542" className="text-body">
                    +351 252 310 542
                  </a>
                </p>

                <p className="mb-6">
                  R. Padre Domingos Joaquim Pereira nº1199 <br />
                  4760-563 Louro <br />
                  Portugal
                </p>
              </div>

              <div className="col-12 col-md-6 d-flex flex-column">
                <div className="m-auto text-center w-md-75">
                  <p className="h2 mt-5 fw-bold">
                    {t("page.contact.countryMissing")}
                  </p>
                  <p>{t("page.contact.contactUsDirectly")}</p>
                  <button
                    className="btn btn-primary shadow"
                    onClick={() => window.scroll({ top: 0 })}
                  >
                    {t("common.contactUs")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
