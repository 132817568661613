import React, { useContext, useState } from "react"
import useTranslation from "../translations/translate"
import LanguageContext from "./LanguageContext"

const ContactForm = () => {
  const { t, getLocalizedPath } = useTranslation()
  const activeLang = useContext(LanguageContext)
  const [data, setData] = useState({})

  return (
    <form
      name={activeLang.toUpperCase()}
      method="post"
      action={getLocalizedPath("thanks")}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="form-name" value={activeLang.toUpperCase()} />
      <p className="d-none">
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <div className="mb-3">
        <label htmlFor="contactFormName" className="form-label">
          {t("component.contactForm.name")} *
        </label>
        <input
          type="text"
          className="form-control"
          id="contactFormName"
          name="name"
          value={data.name}
          onChange={e => setData({ ...data, name: e.target.value })}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="contactFormEmailAddress" className="form-label">
          {t("component.contactForm.email")} *
        </label>
        <input
          type="email"
          className="form-control"
          id="contactFormEmailAddress"
          name="email"
          value={data.email}
          onChange={e => setData({ ...data, email: e.target.value })}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="contactFormCompany" className="form-label">
          {t("component.contactForm.company")} *
        </label>
        <input
          type="text"
          className="form-control"
          id="contactFormCompany"
          name="company"
          value={data.company}
          onChange={e => setData({ ...data, company: e.target.value })}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="contactFormMessage" className="form-label">
          {t("component.contactForm.message")}
        </label>
        <textarea
          name="message"
          className="form-control"
          id="contactFormMessage"
          rows="6"
        />
      </div>
      <button type="submit" className="btn btn-primary w-100">
        {t("component.contactForm.send")}
      </button>
    </form>
  )
}

export default ContactForm
